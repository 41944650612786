import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Menu from '../components/menu';
export const _frontmatter = {
  "path": "/menu",
  "pageType": "card",
  "title": "Menu",
  "backgroundImage": "blackboard",
  "layoutClass": "menu"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Menu mdxType="Menu" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      